<template>
  <div class="breadcrumbs">
    <router-link class="medium text-sm gray-600" v-for="(breadcrumb, index) in breadcrumbs" :key="index" :to="breadcrumb.to">
      {{ $t(breadcrumb.name) }}
    </router-link>
  </div>
</template>

<script>
import { defineComponent, toRefs } from 'vue';

export default defineComponent({
  props: {
    items: {
      type: Array,
      required: true
    }
  },
  setup(props) {
    const { items } = toRefs(props);

    return {
      breadcrumbs: items
    };
  }
});
</script>
