<template>
  <div v-if="route.name && route.name.includes('404')">
    <router-view />
  </div>
  <div v-else>
    <the-header v-if="(route.name && route.name !== 'Main') && (route.name && route.name !== 'School')" />
    <main class="app__height">
      <router-view />
    </main>
    <the-footer :class="{'footer__mobile' : route.name === 'Main'}"  />
    <navbar />
  </div>
  <transition name="slide-fade">
        <div class="CO-message" v-if="status">
            <div class="message-content">
                <div class="message-icon">
                  <div>
                    <img src="@/assets/images/error-icon.svg" alt="success">
                    <span class="message-text" v-if="errMsg">
                    {{ errMsg }}
                    </span>
                  </div>
                  <p v-if="errDscr && errDscr != 'null'">
                   {{ errDscr }}
                  </p>
                </div>
                <div class="option">
                    <img src="./assets/images/x-close-black.svg" alt="" @click="onClose">
                </div>
            </div>
        </div>
  </transition>
</template>

<script setup>
import TheHeader from "@/components/global/TheHeader.vue";
import { computed, onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import { mapState, useStore } from "vuex";
import TheFooter from "./components/global/TheFooter.vue";
import Navbar from "./components/global/Navbar.vue"

const route = useRoute();
const store = useStore();

const isLoading = ref(true);

onMounted(async() => {
  await store.dispatch("global/GetCountriesList").then(() => {
    isLoading.value = false;
  })
})

const status = computed(() => store.state.main.status);
const errMsg = computed(() => store.state.main.errorMessage);
const errDscr = computed(() => store.state.main.errorDescription)

const onClose = () => {
  store.commit('main/SET_ERROR_MESSAGE', { status: false, message: '', description: null });
};

</script>

<style lang="scss">
.app__height {
  min-height: 60vh;
}


.CO-message{
  position: fixed;
  top: 24px;
  right: 24px;
  z-index: 11111111;
  border-radius: 8px;
  border: 1px solid var(--Gray-200, #EAECF0);
  background: var(--Base-White, #FFF);
  padding: 16px;
  min-width: 200px;
  max-width: 800px;
  p{
    margin: 0;
  }
  .message-content{
    display: flex;
    gap: 12px;
    align-items: flex-start;
  }
  .message-icon{
    display: flex;
    flex-direction: column;
    gap: 12px;
    &>div{
      display: flex;
      align-items: center;
      gap: 8px;
      img{
        width: 30px;
        height: 30px;
      }
      span{
        font-weight: 600;
        font-size: 16px;
        line-height: normal;
      }
    }
    p{
      font-size: 16px;
      font-weight: 400;
      line-height: normal;
    }
  }
  .option{
    display: flex;
    align-items: flex-start;
    cursor: pointer;
    img{
      width: 20px;
      height: 20px;
    }
  }
}

@media(max-width: 480px){
  .CO-message{
    right: 16px;
    left: 16px;
  }
}
</style>
