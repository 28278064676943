import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import setupI18n from './helpers/i18n';
import Breadcrumbs from './components/global/Breadcrumbs.vue';
import MainLoader from "@/components/global/MainLoader.vue"
import VueSplide from '@splidejs/vue-splide';
import '@splidejs/splide/dist/css/splide.min.css';
import "./assets/styles/index.scss"
import { getLC } from './helpers/helpers';

const app = createApp(App);

const i18n = setupI18n();

app.config.globalProperties.$getLC = getLC;
 
app.component('Breadcrumbs', Breadcrumbs);
app.component('main-loader', MainLoader);
app.use(store);
app.use(router);
app.use(i18n);
app.use(VueSplide);
app.mount('#app');
