// Импортируйте axiosInstance, если он вам нужен
import axiosInstance from '../../helpers/instance';

const state = {
  token: localStorage.getItem('authToken') || null
};

const getters = {
  isAuthenticated: state => !!state.token
};

const actions = {
  async sendRegisterSms({ commit }, body) {
    try {
      const response = await axiosInstance.post('/auth/send_register_sms', body);

      if (response.data) {
        // commit('setMain', response.data);
        console.log(response.data)
      }

      return response.data;
    } catch (error) {
      console.log('Ошибка:', error);
      throw error;
    }
  },
  async login({ commit }, body) {
    try {
      const response = await axiosInstance.post('/auth/login', body);

      if (response && response.data) {
        if(response.data.data && response.data.data.token){
          commit('setToken', response.data.data.token);
        }
      }

      return response.data;
    } catch (error) {
      throw error;
    }
  },
  async register({ commit }, body) {
    try {
      const response = await axiosInstance.post('/auth/register', body);

      if (response && response.data) {
        if(response.data.data){
          commit('setToken', response.data.data);
        }
      }

      return response.data;
    } catch (error) {
      throw error;
    }
  },
  async confirmSms({ commit }, body) {
    try {
      const response = await axiosInstance.post('/auth/confirm_register_sms', body);

      return response.data;
    } catch (error) {
      throw error;
    }
  },
  logout({ commit }) {
    commit('clearToken');
    window.location.replace('/')
  },
  async fotgetPasswordSms({ commit }, postData) {
    try {
      axiosInstance.defaults.headers.common['Accept-Language'] = localStorage.getItem('lang') || 'ru';

      const response = await axiosInstance.post('/auth/send_change_password_sms', postData);

      return response.data;
    } catch (error) {
      console.log('Ошибка:', error);
      throw error;
    }
  },
  async fotgetPasswordConfirmSms({ commit }, postData) {
    try {
      axiosInstance.defaults.headers.common['Accept-Language'] = localStorage.getItem('lang') || 'ru';

      const response = await axiosInstance.post('/auth/confirm_change_password_sms', postData);

      return response.data;
    } catch (error) {
      console.log('Ошибка:', error);
      throw error;
    }
  },
  async fotgetChangePassword({ commit }, postData) {
    try {
      axiosInstance.defaults.headers.common['Accept-Language'] = localStorage.getItem('lang') || 'ru';

      const response = await axiosInstance.post('/auth/change_password', postData);

      return response.data;
    } catch (error) {
      console.log('Ошибка:', error);
      throw error;
    }
  },
  async getAuthToken({ commit }, body) {
    try {
      axiosInstance.defaults.headers.common['Accept-Language'] = localStorage.getItem('lang') || 'ru';

      const response = await axiosInstance.post('/partner/auth_token', body);

      if (response.data && response.data.err === false) {
        if(response.data && response.data.data){
          commit('setToken', response.data.data);
        }
      }

      return response.data;
    } catch (error) {
      console.log('Ошибка:', error);
      throw error;
    }
  },
};

const mutations = {
  setRegister(state, data) {
    // state.main = data;
  },
  setToken(state, token) {
    state.token = token;
    localStorage.setItem('authToken', token);
  },
  clearToken(state) {
    state.token = null;
    localStorage.removeItem('authToken');
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
