import { createStore } from 'vuex'
import main from "./modules/main"
import auth from "./modules/auth"
import global from './modules/global'
import product from './modules/product'
import profile from './modules/profile'
import order from './modules/order'

export default createStore({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    main,
    auth,
    global,
    product,
    profile,
    order
  }
})
