<template>
  <div class="text-field">
    <label class="gray-700 text-sm medium">{{ $t('selectCountry') }}</label>
    <div class="select" @click="toggleDropdown">
      <span class="select__active">
        <span>
          <img src="../../assets/images/KZ.png" alt="">
          <span class="gray-900 text-md medium" v-if="selectedOption && selectedOption.title">
            {{ selectedOption.title }}
          </span>
        </span>
        <img :class="{ 'active-arrow' : isOpen }" src="@/assets/images/chevron-down.png" alt="">
      </span>
      <ul v-if="isOpen" class="select__options">
        <li v-for="(item, i) in lists" :key="i" @click="selectOption(item)">
          <span class="gray-700 text-sm" v-if="item.title">{{ item.title }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch } from 'vue';
import { defineProps, defineEmits } from 'vue';
import { useStore } from 'vuex';

const store = useStore();

const props = defineProps({
  modelValue: {
    type: String,
    default: null
  },
});

const lists = computed(() => store.state.global.countries)

const emit = defineEmits(['update:modelValue']);

const isOpen = ref(false);

const selectedOption = computed(() => {
  return lists.value.find(option => option.id === props.modelValue);
});

function toggleDropdown() {
  isOpen.value = !isOpen.value;
}

function selectOption(option) {
  if(option && option.id){
    emit('update:modelValue', option.id);
  }
}

watch(() => props.modelValue, (newValue) => {
  console.log("Selected value changed to:", newValue);
});
</script>
