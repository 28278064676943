// Импортируйте axiosInstance, если он вам нужен
import axiosInstance from '../../helpers/instance';

const state = {
  main: null,
  blogCategories: null,
  post: null,
  blogContents: null,
  authModal: false,
  customers: null,
  partners: null,
  about: null,
  schools: null,
  errorMessage: '',
  errorDescription: '',
  status: false
};

const getters = {};

const actions = {
  async getMain({ commit }) {
    try {
      axiosInstance.defaults.headers.common['Accept-Language'] = localStorage.getItem('lang') || 'ru';

      const response = await axiosInstance.get('get_home_page_content/');

      if (response.data) {
        commit('setMain', response.data);
        console.log(response.data)
      }

      return response.data;
    } catch (error) {
      console.log('Ошибка:', error);
      throw error;
    }
  },
  async getBlogCategories({ commit }) {
    try {
      axiosInstance.defaults.headers.common['Accept-Language'] = localStorage.getItem('lang') || 'ru';

      const response = await axiosInstance.get('get_blog_categories/');

      if (response.data) {
        commit('setBlogCategories', response.data);
        console.log(response.data);
      }
    } catch (error) {
      console.log('Ошибка', error);
      throw error;
    }
  },
  async getPost({ commit }, id) {
    try {
      axiosInstance.defaults.headers.common['Accept-Language'] = localStorage.getItem('lang') || 'ru';

      const response = await axiosInstance.get('get_blog_post?id=' + id);

      if (response.data && response.data.data) {
        commit('setBlogPost', response.data.data);
      }
    } catch (error) {
      console.log('Ошибка', error);
      throw error;
    }
  },
  async getBlogContent({ commit }) {
    try {
      axiosInstance.defaults.headers.common['Accept-Language'] = localStorage.getItem('lang') || 'ru';

      const response = await axiosInstance.get('get_blog_content/');

      if (response.data) {
        commit('setBlogContent', response.data);
        console.log(response.data);
      }
    } catch (error) {
      console.log('Ошибка', error);
      throw error;
    }
  },
  handleAuthModal({ commit }, data){
    commit('setAuthModal', data)
  },
  async getCustomers({ commit }) {
    try {
      axiosInstance.defaults.headers.common['Accept-Language'] = localStorage.getItem('lang') || 'ru';

      const response = await axiosInstance.get('for_customers/get_page_blocks');

      if (response.data && response.data.data) {
        commit('setCustomers', response.data.data);
      }
    } catch (error) {
      console.log('Ошибка', error);
      throw error;
    }
  },
  async getPartners({ commit }) {
    try {
      axiosInstance.defaults.headers.common['Accept-Language'] = localStorage.getItem('lang') || 'ru';

      const response = await axiosInstance.get('partners/get_page_blocks');

      if (response.data && response.data.data) {
        commit('setPartners', response.data.data);
      }
    } catch (error) {
      console.log('Ошибка', error);
      throw error;
    }
  },
  async getAbout({ commit }) {
    try {
      axiosInstance.defaults.headers.common['Accept-Language'] = localStorage.getItem('lang') || 'ru';

      const response = await axiosInstance.get('about_company/get_page_blocks');

      if (response.data && response.data.data) {
        commit('setAbout', response.data.data);
      }
    } catch (error) {
      console.log('Ошибка', error);
      throw error;
    }
  },
  async getSchools({ commit }) {
    try {
      axiosInstance.defaults.headers.common['Accept-Language'] = localStorage.getItem('lang') || 'ru';

      const response = await axiosInstance.get('business_school/get_page_blocks');

      if (response.data && response.data.data) {
        commit('setSchools', response.data.data);
      }
    } catch (error) {
      console.log('Ошибка', error);
      throw error;
    }
  },
  showErrorMessage({ commit }, { status, message, description }) {
    commit('SET_ERROR_MESSAGE', { status, message, description });
  },
  async mlm({ commit }, body) {
    try {
      axiosInstance.defaults.headers.common['Accept-Language'] = localStorage.getItem('lang') || 'ru';

      const response = await axiosInstance.post('partner/auth_in_mlm', body);

      if (response.data && response.data) {
        return response.data
      }
    } catch (error) {
      console.log('Ошибка', error);
      throw error;
    }
  },
};

const mutations = {
  setMain(state, data) {
    state.main = data;
  },
  setBlogCategories(state, data) {
    state.blogCategories = data;
  },
  setBlogPost(state, data) {
    state.post = data;
  },
  setBlogContent(state, data) {
    state.blogContents = data;
  },
  setAuthModal(state, data){
    state.authModal = data;
  },
  setCustomers(state, data){
    state.customers = data;
  },
  setPartners(state, data){
    state.partners = data;
  },
  setAbout(state, data){
    state.about = data;
  },
  setSchools(state, data){
    state.schools = data;
  },
  SET_ERROR_MESSAGE(state, { status, message, description }) {
    state.status = status;
    state.errorMessage = message;
    state.errorDescription = description;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
