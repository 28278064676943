// Импортируйте axiosInstance, если он вам нужен
import axiosInstance from '../../helpers/instance';

const state = {
  countries: null,
  selectedCity: (localStorage.getItem('selectedCity')) || 1,
  stocks: null
};

const getters = {};

const actions = {
  async GetCountriesList({ commit }) {
    try {
      axiosInstance.defaults.headers.common['Accept-Language'] = localStorage.getItem('lang') || 'ru';

      const response = await axiosInstance.get('get_countries_list');

      if (response.data && response.data.data) {
        commit('setCountries', response.data.data);
      }

      return response.data;
    } catch (error) {
      console.log('Ошибка:', error);
      throw error;
    }
  },
  async getProductStocks({ commit }, id) {
    try {
      axiosInstance.defaults.headers.common['Accept-Language'] = localStorage.getItem('lang') || 'ru';

      const response = await axiosInstance.get(`/get_product_stocks?product_id=${id}&city_id=${state.selectedCity}`);

      if (response.data && response.data.data) {
        commit('setProductStocks', response.data.data);
      }

      return response.data;
    } catch (error) {
      console.log('Ошибка:', error);
      throw error;
    }
  },
};

const mutations = {
  setCountries(state, data) {
    state.countries = data;
  },
  setSelectedCity(state, cityId) {
    state.selectedCity = cityId;
  },
  setProductStocks(state, data) {
    state.stocks = data;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
