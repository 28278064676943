<template>
  <div class="drawer drawer-right basket" :class="{ 'show-modal': animateModal }">
    <div class="drawer__inner">
      <main-loader v-if="isLoading" class="main__loader-mini" />
      <div v-else class="drawer__wrap">
        <div class="basket__header">
          <div class="basket__header-item">
          </div>
          <span class="drawer__close">
            <img @click="close()" src="@/assets/images/x-close.svg" alt="" />
          </span>
        </div>
        <div class="basket__content order-info" v-if="order">
          <p class="text-xl bold gray-900" v-if="order.id">
            Заказ №{{ order.id }}
          </p>
          <div class="order-info__up">
            <div>
              <span class="gray-500 text-md regular">Дата заказа</span>
              <p class="gray-900 text-md medium">{{ order.created_at }}</p>
            </div>
            <div>
              <span class="gray-500 text-md regular">Итого</span>
              <p class="gray-900 text-md medium">{{ order.total_amount }} ₸</p>
            </div>
            <div>
              <span class="gray-500 text-md regular">Статус</span>
              <p class="gray-900 text-md medium">{{ order.status?.title }}</p>
            </div>
            <div v-if="order.payment_method">
              <span class="gray-500 text-md regular">Способ оплаты</span>
              <p class="gray-900 text-md medium">{{ order.payment_method?.title }}</p>
            </div>
          </div>
          <div class="order-info__products" v-if="order.products && order.products.length > 0">
            <div class="order-info__item" v-for="item in order.products">
              <div class="img">
                <img :src="item.product.photo_uris[0]" v-if="item.product.photo_uris[0]" alt="">
                <img src="../../../assets/images/empty-bg.png" v-else alt="">
              </div>
              <div class="text">
                <p class="text_name">{{ item.product.title }}</p>
                <p class="text_number">Кол-во: {{ item.quantity }}</p>
                <div class="text_price" v-if="item.product.discount_price">
                  <p>{{ formatNumber(item.product.discount_price) }} ₸</p>
                  <span>{{ formatNumber(item.product.price) }} ₸</span>
                </div>
                <div class="text_price" v-if="!item.product.discount_price">
                  <p>{{ formatNumber(item.product.price) }} ₸</p>
                </div>
              </div>
            </div>
          </div>
          <div class="order-info__prices" v-if="order.price">
            <div class="order-info__prices_item">
              <p>Стоимость продуктов</p>
              <span>{{ formatNumber(order.price.total_usual_price) }} ₸</span>
            </div>
            <div class="order-info__prices_item" v-if="order.price.promocode_value_from_price">
              <p>Скидка</p>
              <span>- {{ formatNumber(order.price.promocode_value_from_price) }} ₸</span>
            </div>
            <div class="order-info__prices_item">
              <p>Итого</p>
              <span>{{ formatNumber(order.price.total_price) }} ₸</span>
            </div>
          </div>
          <div class="order-info__address">
            <div class="order-info__address_item">
              <p class="address_title">Способ доставки</p>
              <p class="address_street">{{ order.delivery_method?.title }}</p>
            </div>
            <div class="order-info__address_item" v-if="order.delivery_method?.need_address_to_delivery === 1">
              <p class="address_title">Адрес доставки</p>
              <p class="address_city">г. Алматы</p>
              <p class="address_street">Розыбакиева 289</p>
              <div class="address_detail">кв/офис: 27 подъезд: 3 этаж: 9</div>
            </div>
            <div class="order-info__address_item" v-if="order.delivery_method?.need_address_to_delivery === 0">
              <p class="address_title">Адрес ПВЗ</p>
              <p class="address_city">г. {{ order.warehouse?.city?.title }}</p>
              <p class="address_street">{{ order.warehouse?.title }}</p>
              <p class="address">{{ order.warehouse?.address }}</p>
              <p>{{ order.warehouse?.work_time }}</p>
            </div>
            <div class="order-info__address_item">
              <p class="address_title">Получатель</p>
              <p class="address_city">{{ order.recipient_name }}</p>
              <p class="address_city">{{ order.recipient_phone_number }}</p>
            </div>
          </div>
<!--          <div class="order-info__payment">-->
<!--            <div class="order-info__payment_item">-->
<!--              <p class="payment_title">Способ оплаты</p>-->
<!--              <p>{{ order.payment_method.title }}</p>-->
<!--              &lt;!&ndash; <p>Visa заканчивающаяся на 0433</p> &ndash;&gt;-->
<!--            </div>-->
<!--          </div>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, defineProps, defineEmits, onMounted, computed } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

import { formatNumber } from '@/helpers/helpers';

const props = defineProps({
  orderId: {
    type: Number,
    required: true
  }
});

const router = useRouter();

const emits = defineEmits(["closeModal"]);

const store = useStore();

const isLoading = ref(true);

const animateModal = ref(false);

const order = computed(() => store.state.order.order_details);

const fetchOrderDetails = async (orderId) => {
  try {
    const response = await store.dispatch("order/getOrderDetails", orderId);

    isLoading.value = false;
  } catch (error) {
    isLoading.value = false;
  }
};

const close = () => {
  animateModal.value = false;

  setTimeout(() => {
    emits("closeModal");
  }, 200);
};

onMounted(async() => {
  setTimeout(() => {
    animateModal.value = true;
  }, 80);

  await fetchOrderDetails(props.orderId);
});
</script>

<style></style>
