<template>
  <div class="drawer drawer-left" :class="{ 'show-modal': animateModal }">
    <div class="drawer__inner" v-if="!isLoading">
      <div class="drawer__wrap">
        <div class="drawer__header">
          <div class="country">
            <div
              class="country__selected cursor-pointer"
              v-if="countrySelect"
              @click="openCountryDropDown = !openCountryDropDown"
            >
              <!-- <img 
                class="country__logo" 
                :src="require(`@/assets/images/${countrySelect.icon}`)" 
              > -->
              <span class="text-lg semibold gray-900">
                {{ countrySelect.title }}
              </span>
              <img
                class="country__arrow"
                :class="{ active: openCountryDropDown }"
                src="../../../assets/images/chevron-down.png"
                alt=""
              />
            </div>
            <ul class="country__list" v-if="openCountryDropDown">
              <li
                :class="{ active: item.id === countrySelect.id }"
                v-for="(item, i) in countries"
                @click="changeCountry(item)"
                :key="i"
              >
                <!-- <img 
                  class="country__logo" 
                  :src="require(`@/assets/images/${item.icon}`)" 
                > -->
                <span class="text-md medium gray-900">
                  {{ item.title }}
                </span>
                <img
                  v-if="item.id === countrySelect.id"
                  class="country__check"
                  src="@/assets/images/check.svg"
                  alt=""
                />
              </li>
            </ul>
          </div>
          <span class="drawer__close">
            <img @click="close()" src="@/assets/images/x-close.svg" alt="" />
          </span>
        </div>

        <div class="text-field text-field-search">
          <input type="search" placeholder="Поиск города" v-model="searchQuery" />
        </div>

        <ul class="city__list" v-if="countrySelect && filteredCities.length">
          <li
            class="text-md gray-900 medium cursor-pointer"
            v-for="(item, i) in filteredCities"
            :key="i"
            @click="selectCity(item)"
          >
            {{ item.title }}
            <img
              v-if="item.id == selectedCity"
              class="country__check"
              src="@/assets/images/check.svg"
              alt=""
            />
          </li>
        </ul>
      </div>
      <div class="drawer__actions">
        <button class="button text-lg semibold">{{$t('select')}}</button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, defineProps, defineEmits, onMounted, computed } from "vue";
import { useStore } from "vuex";

defineProps();

const store = useStore();

const emits = defineEmits(["closeModal"]);

const animateModal = ref(false);

const activeTab = ref(1);

const countries = computed(() => store.state.global.countries);

const selectedCity = computed(() => store.state.global.selectedCity);

const countrySelect = ref();

const isLoading = ref(true);

const openCountryDropDown = ref(false);

const searchQuery = ref("");

const changeCountry = (event) => {
  countrySelect.value = event;
  openCountryDropDown.value = false;
  searchQuery.value = "";
};

const filteredCities = computed(() => {
  if (!countrySelect.value) return [];
  return countrySelect.value.cities.filter((city) =>
    city.title.toLowerCase().includes(searchQuery.value.toLowerCase())
  );
});

const close = () => {
  animateModal.value = false;

  setTimeout(() => {
    emits("closeModal");
  }, 200);
};

const selectCity = (city) => {
  if (city && city.id) {
    store.commit("global/setSelectedCity", city.id);
    localStorage.setItem("selectedCity", city.id);

    close();
  }
};

onMounted(() => {
  const defaultCountry = countries.value.find((country) => country.id === 1);

  if (defaultCountry) {
    countrySelect.value = defaultCountry;

    if (defaultCountry.cities) {
      const savedCity = localStorage.getItem("selectedCity");
      if (savedCity) {
        selectedCity.value = savedCity;
      } else {
        let city = defaultCountry.cities.find((el) => el.id === 1);
        if (city) {
          selectCity(city);
        }
      }
    }
  }

  isLoading.value = false;

  setTimeout(() => {
    animateModal.value = true;
  }, 80);
});
</script>

<style></style>
