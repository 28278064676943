<template>
  <header
    class="header"
    :class="{
      'header__search-active': settingsState.searchState,
      'main-header': headerColor && !settingsState.searchState,
      'header__burger-active': settingsState.burgerMenu
    }"
  >
    <div class="header__wrap">
      <div class="container">
        <div class="header__inner">
          <div class="header__up">
            <div class="left">
              <div class="city cursor-pointer" @click="selectCityModal">
                <img
                  v-if="headerColor && !settingsState.searchState"
                  src="../../assets/images/location-white.svg"
                  alt=""
                />
                <img v-else src="../../assets/images/location.svg" alt="" />
                <span
                  class="text-md semibold gray-blue-900"
                  :class="{ white: headerColor && !settingsState.searchState }"
                >
                  {{cityName(cityId)}}
                </span>
              </div>
              <div class="language">
                <div
                  @click="languageDropDown = !languageDropDown"
                  class="language__active gray-blue-900 text-md semibold cursor-pointer"
                  :class="{ white: headerColor && !settingsState.searchState }"
                >
                  {{ language.title }}
                  <img
                    v-if="headerColor && !settingsState.searchState"
                    :class="{ active: languageDropDown }"
                    src="../../assets/images/chevron-down-white.svg"
                    alt=""
                  />
                  <img
                    v-else
                    :class="{ active: languageDropDown }"
                    src="../../assets/images/chevron-down-header.svg"
                    alt=""
                  />
                </div>
                <ul class="language__list" v-if="languageDropDown">
                  <li
                    @click="changeLanguage(item)"
                    class="cursor-pointer"
                    v-for="(item, i) in languages"
                    :key="i"
                  >
                    <span class="text-md medium gray-900">
                      {{ item.title }}
                    </span>
                    <img
                      v-if="item.code === language.code"
                      src="@/assets/images/check.svg"
                      alt=""
                    />
                  </li>
                </ul>
              </div>
            </div>
            <div class="logo cursor-pointer" @click="redirect('/')">
              <img
                v-if="headerColor && !settingsState.searchState"
                src="../../assets/images/logo-white.svg"
                alt=""
              />
              <img v-else src="../../assets/images/logo.svg" alt="" />
            </div>
            <div class="setting">
              <div class="icon cursor-pointer" @click="searchProduct()">
                <img
                  v-if="headerColor && !settingsState.searchState"
                  src="../../assets/images/search-white.svg"
                  alt=""
                />
                <img
                  v-else-if="settingsState.searchState"
                  :src="require(`@/assets/images/x-close-black.svg`)"
                  alt=""
                />
                <img v-else src="@/assets/images/search.svg" alt="">
              </div>
              <div class="icon cursor-pointer" @click="favoritesFunc()">
                <img
                  v-if="headerColor && !settingsState.searchState"
                  src="../../assets/images/heart-white.svg"
                  alt=""
                />
                <img v-else src="@/assets/images/heart.svg" alt="">
              </div>
              <div class="icon cursor-pointer" @click="toggleAuthModal()">
                <img
                  v-if="headerColor && !settingsState.searchState"
                  src="../../assets/images/user-circle-white.svg"
                  alt=""
                />
                <img v-else src="@/assets/images/user-circle.svg" alt="">
              </div>
              <div class="icon cursor-pointer" @click="toggleBasketModal()">
                <img
                  v-if="headerColor && !settingsState.searchState"
                  src="../../assets/images/shopping-bag-white.svg"
                  alt=""
                />
                <img v-else src="@/assets/images/shopping-bag.svg" alt="">
              </div>
            </div>
          </div>
          <nav class="header__nav">
            <ul>
              <li
                @click="redirect(item.link)"
                class="text-md regular gray-blue-900"
                :class="{ white: headerColor && !settingsState.searchState }"
                v-for="(item, i) in menu"
                :key="i"
              >
                {{ $t(item.title) }}
              </li>
            </ul>
          </nav>
          <div class="header__search" v-if="settingsState.searchState">
            <input
              @keyup.enter="searchRedirect()"
              v-model="search"
              type="text"
              :placeholder="$t('searchPlaceholder')"
            />
          </div>
          <div class="header__burger" @click="burgerOpen">
            <img v-if="settingsState.burgerMenu" src="../../assets/images/burger-close.svg" alt="">
            <img v-else src="../../assets/images/burger.svg" alt="">
          </div>
        </div>
      </div>
    </div>
  </header>
  <auth-modal
    v-if="settingsState.authModal || storeAuthModal"
    @closeModal="()=>{
      settingsState.authModal = false
      store.dispatch('main/handleAuthModal', false)
    }"
  />
  <select-city
    v-if="settingsState.citySelectModal"
    @closeModal="settingsState.citySelectModal = false"
  />
  <basket-modal
    v-if="settingsState.basketModal"
    @closeModal="settingsState.basketModal = false"
  />
  <burger v-if="settingsState.burgerMenu" @closeBurger="settingsState.burgerMenu = false" />
</template>

<script setup>
import AuthModal from "@/components/partials/Modals/AuthModal.vue";
import { computed, ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import SelectCity from "../partials/Modals/SelectCity.vue";
import BasketModal from "../partials/Modals/BasketModal.vue";
import Burger from "../global/Burger.vue"
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import {
  SUPPORT_LOCALES as supportLocales,
  setI18nLanguage,
} from "@/helpers/i18n";

const router = useRouter();

const store = useStore();

const { t, locale } = useI18n({ useScope: "global" });

const props = defineProps({
  state: {
    type: String,
    default: "",
  },
});

const headerColor = computed(() => props.state);

const storeAuthModal = computed(() => store.state.main.authModal);

const countries = computed(() => store.state.global.countries)

const cityId = computed(() => store.state.global.selectedCity);

const settingsState = ref({
  authModal: false,
  searchState: false,
  citySelectModal: false,
  basketModal: false,
  burgerMenu: false
});

const search = ref("");

const menu = ref([
  {
    title: "main",
    link: "/",
  },
  {
    title: "catalog",
    link: "/catalog",
  },
  {
    title: "company",
    link: "/about",
  },
  {
    title: "blog",
    link: "/blog",
  },
  {
    title: "partnership",
    link: "/partners",
  },
  {
    title: "clients",
    link: "/clients",
  },
  {
    title: "school",
    link: "/school",
  },
]);

const languageDropDown = ref(false);

const language = ref({
  title: "Русский",
  code: "RU",
});

const languages = ref([
  {
    title: "Қазақша",
    code: "kk",
  },
  {
    title: "Русский",
    code: "ru",
  },
  {
    title: "English",
    code: "en",
  },
]);

const changeLanguage = (item) => {
  language.value = item;
  setI18nLanguage(item.code);
  localStorage.setItem("selectedLanguage", JSON.stringify(item));
  languageDropDown.value = !languageDropDown.value
};

const redirect = (event) => {
  if (event) {
    router.push(event);
  }
};

const cityName = (id) => {
  if (id && countries.value) {
    const country = countries.value.find((el) => el.title === 'Казахстан');
    if (country && country.cities) {
      const city = country.cities.find((city) => city.id == id);
      return city ? city.title : '';
    }
  }

  return '';
};

const toggleAuthModal = (event) => {
  if (store.getters["auth/isAuthenticated"]) {
    router.push("/profile");
  } else {
    settingsState.value.authModal = !settingsState.value.authModal;
  }
};

const toggleBasketModal = (event) => {
  if (store.getters["auth/isAuthenticated"]) {
    settingsState.value.basketModal = !settingsState.value.basketModal;
  }else{
    settingsState.value.authModal = true;
  }
  
};

const searchProduct = () => {
  settingsState.value.searchState = !settingsState.value.searchState;
};

const selectCityModal = () => {
  settingsState.value.citySelectModal = !settingsState.value.citySelectModal;
};

const favoritesFunc = () => {
  router.push("/favorites");
};

const burgerOpen = () => {
  settingsState.value.burgerMenu = !settingsState.value.burgerMenu;
}

const searchRedirect = () => {
  router.push("/search/" + search.value);
  settingsState.value.searchState = false;
};

onMounted(() => {
  const savedLanguage = localStorage.getItem("selectedLanguage");
  if (savedLanguage) {
    language.value = JSON.parse(savedLanguage);
    setI18nLanguage(language.value.code);
  }
});
</script>

<style></style>
